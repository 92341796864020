import { render, staticRenderFns } from "./OtherUserProfile.vue?vue&type=template&id=21e93ae4&scoped=true&"
import script from "./OtherUserProfile.vue?vue&type=script&lang=js&"
export * from "./OtherUserProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e93ae4",
  null
  
)

export default component.exports