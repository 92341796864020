<template>
  <swiper ref="mySwiper" :options="swiperOptions" @slideChange="changeGem" @ready="changeGem">
    <swiper-slide class="profile-status-avatar" v-for="(level, key) in activeCircles" :key="key">
        <div class="profile-status-bg" :class="level">
        </div>
    </swiper-slide>
  </swiper>
</template>
<script>
import EventBus from '../eventBus'
export default {
  name: 'UserProfielStatusSlider',
  props: {
    levels: Array,
    gem: Array,
    gem_lvl: Number,
    loggedUser: Boolean
  },
  data () {
    return {
      avatar: 'avatar-image-big.jpg',
      activeCircles: [],
      activeLevel: this.gem_lvl,
      slideIndex: '',
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        observer: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false
        }
      }
    }
  },
  created () {
    if (!this.loggedUser) {
      for (let i = 0; i <= this.activeLevel; i++) {
        this.activeCircles.push(this.levels[i])
      }
    } else {
      this.activeCircles = this.levels
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    this.swiper.slideTo(this.activeLevel, 500, false)
  },
  methods: {
    changeGem () {
      this.slideIndex = this.$refs.mySwiper.$swiper.activeIndex
      var el = this.gem[this.slideIndex]
      const payload = {
        label_1: el.label_1,
        label_2: el.label_2,
        label_3: el.label_3,
        label_4: el.label_4 ? el.label_4 : '',
        value_1: el.value_1,
        value_2: el.value_2,
        value_3: el.value_3,
        value_4: el.value_4 ? el.value_4 : 0
      }
      EventBus.$emit('gamification-levels', payload)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
