<template>
  <div>
    <div v-if="isLoading" class="app-content-box bg-gray" >
      <Loader />
    </div>
    <div v-else-if="!isLoading" class="bg-gray pb-5">
      <OtherUserHome :id="id" :user_informations="userInformations" @update-friend="getUpdate"/>
      <BookfaceSlider v-if="bookfaces.length" :bookfaces="bookfaces" :count="bookfaces.length" :label="'#Bookface użytkownika'" :sliderType="'profile'"/>
      <OtherUserGamification v-if="isPublic == 1"/>
      <OtherUserCommons v-if="isPublic == 1" :commons="commons" @update="getUpdate"/>
      <OtherUserTopShelves :library="top" v-if="top.length"/>
      <OtherUserLibrary v-if="isPublic == 1 && library.length" :library="library" />
    </div>
  </div>
</template>
<script>
import Loader from '../components/Loader'
import OtherUserHome from '../components/OtherUserHome'
import OtherUserGamification from '../components/OtherUserGamification'
import OtherUserCommons from '../components/OtherUserCommons'
import OtherUserLibrary from '../components/OtherUserLibrary'
import OtherUserTopShelves from '../components/OtherUserTopShelves'
import EventBus from '../eventBus'
import BookfaceSlider from '../components/BookfaceSlider'
export default {
  name: 'OtherUserProfile',
  props: {
    id: [String, Number]
  },
  components: {
    Loader,
    OtherUserHome,
    OtherUserGamification,
    OtherUserCommons,
    OtherUserLibrary,
    OtherUserTopShelves,
    BookfaceSlider
  },
  data () {
    return {
      isLoading: true,
      isPublic: null,
      commons: [],
      library: [],
      top: [],
      elementId: 0,
      bookfaces: [],
      bookfacePage: 1,
      bookfacesTotal: 0,
      fetchingBookfaces: false,
      loadMoreBookfaces: true
    }
  },
  methods: {
    getUpdate () {
      this.fetchData()
    },
    fetchData () {
      this.isLoading = true
      this.$https('/profile/' + this.id, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(res => {
          const el = res.data.response
          if (el[0].items.is_bookshop === '1') {
            this.$router.push({ name: 'BookstoreDetails', params: { id: el[0].items.bookshop_id } })
          } else {
            const gamIndex = el.length - 1
            this.commons = el[1]
            this.userInformations = el[0]
            this.$store.commit('updateOtherUserData', el[0])
            this.$store.commit('updateOtherUserGam', el[gamIndex].items)
            this.isPublic = el[0].items.show_profile
            this.library = []
            this.top = []
            for (let i = 3; i < el.length - 1; i++) {
              if (el[i].items.length) {
                this.library.push(el[i])
              }
            }
            this.library.forEach(e => {
              if (e.type === 'top') {
                this.top.push(e)
              }
            })
            this.getBookfaces()
            this.isLoading = false
          }
        })
        .catch(error => {
          console.log('', error)
        })
    },
    getBookfaces () {
      if (!this.fetchingBookfaces) {
        this.fetchingBookfaces = true
        const data = new FormData()
        data.append('id', this.id)
        data.append('type', 'user')
        data.append('page', this.bookfacePage)
        this.$https.post('/bookface/get-bookfaces', data, {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          .then(res => {
            // const items = res.data.response.items
            // this.bookfacesTotal = res.data.response.total
            this.bookfaces = res.data.response
            // if (this.bookfaces.length >= this.bookfacesTotal) {
            //   this.loadMoreBookfaces = false
            // }
            //
            // if (this.loadMoreBookfaces) {
            //   items.forEach(element => {
            //     this.bookfaces.push(element)
            //   })
            //   this.bookfacePage = this.bookfacePage + 1
            //   EventBus.$emit('update-swiper', false)
            // }
            this.fetchingBookfaces = false
          })
          .catch(error => {
            console.log('', error)
          })
      }
    }
  },
  async created () {
    await this.fetchData()
    EventBus.$on('update-friend', (data) => {
      this.fetchData()
    })
    // EventBus.$on('load-more-bookfaces', this.getBookfaces)
  },
  watch: {
    $route () {
      this.fetchData()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
