<template>
    <div>
      <div class="sliding-nav">
        <ul class="nav nav-tabs">
          <li v-for="(tab, key) in tabs" :key="key">
            <a
              class="tab-heads"
              :class="{ active: active === tab.label, 'tab-small': small}"
              @click="switchTab(tab.label, key)"
              >
                <slot :name="tabHeadSlotName(tab.label)">
                  {{tab.label}}
                </slot>
              </a
            >
          </li>
        </ul>
      </div>
    <div>
    <div class="tab-content">
      <div class="growing-cover-slider tab-pane-slider swiper-container">
        <div>
          <slot :name="tabPanelSlotName" v-if="active !== 'Osoby'">
            <UserProfileSlider :props="proposedData" :componentId="idName" @updated-later="updateBooks"/>
          </slot>
          <slot :name="tabPanelSlotName" v-else>
            <PeopleSlider :people="proposedData" @update="updateFriends" />
          </slot>
        </div>
        <div class="container" v-if="active !== 'Osoby'">
          <div class="profile-book-name text-center">
          <router-link :to="{name: 'Authors', params: {id: bookData.autor_id}}" class="text-dark"><div class="book-heading-small mt-2 text-center">{{bookData.autor}}</div></router-link>
          <router-link :to="{name: 'BooksDetails', params: {id: bookData.id}}" class="text-dark"><div class="book-heading-big mt-2 text-center">{{bookData.tytul}}</div></router-link>
        </div>
          <div class="stars-wrapper mt-1" v-if="bookData.rating > 0">
            <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
            inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
            :border-width='borderWidth' :show-rating="false" :read-only="true"
            :rounded-corners='rounded' :rating="bookData.rating" >
            </star-rating>
          </div>
          <div class="rating-size text-center mt-2" v-if="bookData.rating > 0">
            Ocena: {{bookData.rating}}
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
</template>
<script>
import EventBus from '../eventBus'
import UserProfileSlider from './UserProfileSlider'
import StarRating from 'vue-star-rating'
import PeopleSlider from './PeopleSlider'
// import EmptyShelf from './EmptyShelf'
export default {
  name: 'TabsProposed',
  components: {
    UserProfileSlider,
    PeopleSlider,
    StarRating
  },
  props: {
    tabs: Array,
    activeTab: String,
    small: Boolean,
    selectedSlides: Array,
    activeBook: Array,
    idName: String
  },
  data () {
    return {
      active: this.activeTab,
      isLoaded: false,
      selectedBooks: this.selectedSlides,
      idToSlider: 'proposed',
      bookData: { autor: '', tytul: '', rating: 0, id: 0, autor_id: 0 },
      proposedData: [],
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18
    }
  },
  created () {
    EventBus.$on('books-in-proposed', (payload) => {
      this.updateData(payload)
    })
    this.getProposed(0)
  },
  computed: {
    tabPanelSlotName () {
      return 'tab-panel-' + this.active
    }
  },
  mounted () {
    EventBus.$on('books-in-proposed', (payload) => {
      this.updateData(payload)
    })
  },
  methods: {
    updateFriends () {
      this.$emit('update')
    },
    updateBooks () {
      this.$emit('updated-books')
    },
    tabHeadSlotName (tabName) {
      return 'tab-head-' + tabName
    },
    switchTab (tabName, key) {
      this.active = tabName
      this.getProposed(key)
    },
    updateData (payload) {
      this.bookData = payload
    },
    getProposed (key) {
      this.proposedData = this.tabs[key].items
    }
  },
  beforeDestroy () {
    EventBus.$off('books-in-proposed', (payload) => {
      this.updateData(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .tab-small {
    font-size: 16px;
  }

  .tab-content{
    min-height: 0px;
  }
  .stars-wrapper {
  display: flex;
  justify-content: center;
  }
  .rating-size {
    font-size: 14px;
  }
  .nav.nav-tabs{
    @media screen and (max-width: 320px) {
      margin: 10px 0;
    }
  }

  .book-heading-big{
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
  }
</style>
