<template>
  <div class=" bg-gray" id="user-library">
    <div class="container wrapper-other-library">
      <div class="top-heading-small text-center">Biblioteka</div>
    </div>
        <OtherUserTabs :activeTab="activeTab" compId="otherUserLibrary" :tabs="tabs" :library="userShelves">
          <div v-for="(e, key) in library" :key="key">
          <template :slot="'tab-head-' + e.id" >
          </template>
          <template :slot="'tab-panel-' + e.id">
          </template>
        </div>
    </OtherUserTabs>
  </div>
</template>
<script>
import OtherUserTabs from './OtherUserTabs'
export default {
  name: 'OtherUserLibrary',
  props: {
    library: Array
  },
  components: {
    OtherUserTabs
  },
  created () {
    this.library.forEach(e => {
      if (e.type !== 'top') {
        this.userShelves.push(e)
      }
    })
    this.library.forEach(e => {
      if (e.type !== 'top') {
        this.tabs.push({
          id: e.id,
          name: e.label
        })
      }
    })
  },
  methods: {
  },
  data () {
    return {
      activeTab: this.library[0].id,
      shelves: [],
      tabs: [],
      userShelves: []
    }
  }
}
</script>
<style lang="scss" scoped>
  #user-library {
    padding-bottom: 100px;
  }
</style>
