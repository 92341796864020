<template>
    <div class="bookface-container" :class="{profile: this.sliderType === 'profile'}">
        <h3 class="text-center heading">{{label}}</h3>
        <div class="count text-center">{{count}}</div>

        <swiper ref="bookfaceSwiper" :options="swiperOptions">
            <swiper-slide class="create" v-if="this.sliderType !== 'profile'">
                <router-link :to="{name: 'BookfaceCreate', params: {book_id: book_id}}" class="bookface-slide" :style="{backgroundImage: 'url(' + plainUrl + userImage + ')'}">
                    <div class="bottom">
                        <div class="plus">&nbsp;</div>
                        <div class="add">
                            Utwórz<br> Bookface
                        </div>
                    </div>
                </router-link>
            </swiper-slide>
            <swiper-slide v-for="(bookface, key) in bookfaces" :key="key" :id="key">
                <router-link :to="{name: 'Bookface', params: {bookface_id: bookface.id, type: sliderType === 'profile' ? 'book' : 'user'}}" class="bookface-slide" :style="{backgroundImage: 'url(' + bookfaceUrl + bookface.image + ')'}">
                    <div v-if="sliderType === 'profile'" class="name">
                        {{bookface.title.substring(0, 18)}}{{bookface.title.length > 18 ? '...' : ''}}
                    </div>

                    <div v-if="sliderType === 'book'" class="name">
                        {{ bookface.user_name }}<br>
                        {{ bookface.user_surname }}
                    </div>
                </router-link>
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
import { Swiper } from '../../node_modules/vue-awesome-swiper'
import EventBus from '../eventBus'
export default {
  name: 'BookfaceSlider',
  components: {
    Swiper
  },
  props: {
    bookfaces: Array,
    count: [Number, String],
    book_id: [Number, String],
    label: String,
    sliderType: String
  },
  data () {
    return {
      loaded: false,
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: false,
        slidesPerView: 'auto',
        spaceBetween: 10,
        observer: true,
        simulateTouch: true,
        slideIndex: 0,
        lazy: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false
        }
      }
    }
  },
  created () {
    EventBus.$on('update-swiper', () => {
      this.swiper.updateSlides()
      this.swiper.update()
    })
  },
  updated () {
    this.swiper.update()
  },
  computed: {
    swiper () {
      return this.$refs.bookfaceSwiper.$swiper
    },
    userImage: {
      get () {
        return this.$store.state.userData.img_url
      }
    }
  },
  mounted () {
    this.swiper.slideTo(0, 500, true)
  },
  watch: {

  },
  methods: {
    slide () {
      this.slideIndex = this.swiper.activeIndex
      if (this.slideIndex >= this.swiper.slides.length - 5) {
        this.l = false
        EventBus.$emit('load-more-bookfaces', false)
      }
    },
    updated () {
      EventBus.$emit('bookface-updated', false)
    }
  }
}
</script>
<style lang="scss" scoped>
    .bookface-container{
        overflow: hidden;
        padding: 0 32.5px 20px;
        position: relative;

        &.profile{
            padding: 20px 32.5px 40px;
        }
        .heading{
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 2.63;
            text-align: center;
            margin: 0;
        }

        .count{
            font-family: Roboto, sans-serif;
            font-size: 28px;
            font-weight: 300;
            line-height: 1.5;
            padding-bottom: 20px;
        }

        .swiper-container{
            overflow: visible;
        }

        .swiper-slide{
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
            border-radius: 18px;
            max-width: 129px;
        }

        /*.swiper-slide-prev{*/
        /*    opacity: 0;*/
        /*}*/

        .bookface-slide{
            background-color: #1f1f1f;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 233px;
            border-radius: 18px;
            display: flex;
            align-items: flex-end;
            padding: 15px;

            .name{
                color: #fff;
                font-family: Roboto, sans-serif;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
            }
        }

        .create{
          .bookface-slide{
              padding: 0;

              .bottom{
                  width: 100%;
                  height: 30%;
                  padding: 0 10px 10px;
                  background:  #ffbc00;
                  text-align: center;
                  border-bottom-right-radius: 18px;
                  border-bottom-left-radius: 18px;
                  display:flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;

                  .plus{
                      background: transparent url('../assets/images/bf-plus.svg') no-repeat center;
                      background-size: cover;
                      position: absolute;
                      top: -20px;
                      left: calc(50% - 19px);
                      width: 38px;
                      height: 38px;
                  }

                  .add{
                      font-family: Roboto, sans-serif;
                      font-size: 14px;
                      font-weight: 500;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.14;
                      color: #000000;
                      letter-spacing: normal;
                      margin-top:10px;
                  }
              }
          }
        }
    }

    .bf-loader{
        width: 100%;
        height: 50%;
        opacity: 0.8;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
    }
</style>
