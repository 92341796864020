<template>
    <div>
        <swiper ref="mySwiper" :options="swiperOptions" @slideChange="sendData" @ready="sendData">
            <div class="people-slider-avatar growing-slide swiper-slide slide-wrapper" v-for="(person, key) in people"
                 :key="key">
                <swiper-slide>
                    <div class="growing-slide-avatar-container books-featured-slide-avatar-container">
                        <div class="growing-slide-avatar bottom-add-sign">
                            <div>
                                <router-link
                                        :to="{name: 'OtherUserProfile', params: {id: person.user_id ? person.user_id : person.id}}">
                                    <img
                                        :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')"
                                        :alt="person.username"
                                    />
                                </router-link>
                            </div>
                            <div @click="addFriend(person)">
                                <span class="remove-button-small" v-if="person.is_friend"></span>
                                <span class="add-sign-button-small" v-else></span>
                            </div>
                        </div>
                    </div>
                    <router-link class="text-dark" :to="{name: 'OtherUserProfile', params: {id: person.user_id ? person.user_id : person.id}}">
                      <div class="text-center top-heading-small">{{person.username}}</div>
                    </router-link>
                    <ModalSlot title="" v-if="isPopupShown && (check_id === person.id)" @close-modal="closeModal(false)">
                      <div v-if="isLoading"> <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" /></div>
                      <div v-else>
                        <div v-if="isFriend == true">
                            <h2 class="text-center mb-4">Dodaj osobę do biblioteki</h2>
                            <div class="info-wrapper">
                              <img
                                      :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')"
                                      :alt="person.username"
                              />
                            <div class="mt-3">{{person.username}}</div>
                            </div>
                            <div>
                              <button type="button" @click="addNewFriend(person.id)"  class="btn-black-full w-100 mt-3">Dodaj</button>
                              <button type="button" class="btn-gray-border w-100 mt-3 mb-2" @click="closeModal">Anuluj</button>
                            </div>
                          </div>
                          <div v-if="isFriend == false">
                            <h2 class="text-center mb-2">Ta osoba znajduje się już w Twoich znajomych.</h2>
                            <h4 class="text-center mb-4">Czy na pewno chcesz ją usunąć?</h4>
                            <div class="info-wrapper">
                              <img
                                      :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')"
                                      :alt="person.username"
                              />
                              <div class="mt-3">{{person.username}}</div>
                            </div>
                            <div>
                              <button type="button" @click="removeFriend(person.id)"  class="btn-black-full w-100 mt-3">Usuń</button>
                              <button type="button" class="btn-gray-border w-100 mt-3 mb-2" @click="closeModal()">Anuluj</button>
                            </div>
                          </div>
                      </div>
                    </ModalSlot>
                    <ModalSlot title="" v-if="isAddedPerson && (check_id === person.id)" @close-modal="closeModal(false)">
                      <div class="text-center mb-2">Dodawanie użytkownika {{person.username}} do listy znajomych</div>
                      <div class="d-flex justify-content-center align-items-center"><img src="../assets/loaders/loader_on_overlay.gif" alt="loader" /></div>
                    </ModalSlot>
                </swiper-slide>
            </div>
        </swiper>
      <!--  <ModalAddFriend v-if="isModalOpen && isLoaded"
                        @close-modal="closeModal"
                        :userdata="modalData"
                        :isFriend="isFriend"
        /> -->
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from '../../node_modules/vue-awesome-swiper'
// import ModalAddFriend from '../components/ModalAddFriend'
import ModalSlot from '../components/ModalSlot'
// import EventBus from '../eventBus'
export default {
  name: 'PeopleSlider',
  props: {
    people: Array
  },
  components: {
    Swiper,
    SwiperSlide,
    // ModalAddFriend,
    ModalSlot
  },
  data () {
    return {
      isModalOpen: true,
      isPopupShown: false,
      isLoading: false,
      isAddedPerson: false,
      check_id: 0,
      isFriend: '',
      modalData: [],
      slideIndex: 0,
      isLoaded: false,
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        observer: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false
        }
      }
    }
  },
  computed: {
    my_id () {
      return this.$store.state.my_id
    },
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  watch: {
    people: function () {
      this.swiper.slideTo(0, 500, true)
      this.id = this._uid
      this.sendData()
    }
  },
  methods: {
    sendData () {
      this.slideIndex = this.$refs.mySwiper.$swiper.activeIndex
    },
    closeModal (e) {
      this.isModalOpen = false
      this.check_id = 0
      this.isPopupShown = false
    },
    removeFriend (id) {
      this.isLoading = true
      const getData = `user=${id}`
      this.$https('/remfriends', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$emit('update')
      })
        .catch(error => {
          console.log('', error)
        })
      this.isLoading = false
      this.closeModal()
    },
    addNewFriend (id) {
      this.isPopupShown = false
      this.isAddedPerson = true
      const getData = `user=${id}`
      this.$https('/friends', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$emit('update')
        this.isAddedPerson = false
      })
        .catch(error => {
          console.log('', error)
        })
    },
    addFriend (person) {
      this.isLoaded = false
      this.modalData = []
      this.modalData.push({
        id: person.id,
        thumb_url: person.thumb_url,
        username: person.username
      })
      if (person.id === this.my_id) {
      } else if (person.is_friend == null) {
        this.isLoaded = true
        this.isFriend = true
        this.isModalOpen = true
        this.isPopupShown = true
        this.check_id = person.id
      } else {
        this.isFriend = false
        this.isModalOpen = true
        this.isLoaded = true
        this.isPopupShown = true
        this.check_id = person.id
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.growing-slide-avatar-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    // height: 210px;
    padding: 0 0;

    &.books-featured-slide-avatar-container {
        padding-bottom: 25px;
    }

    img {
        width: 100%;
    }
}
.slide-wrapper{
    min-height: 50vw;
}
.info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-wrapper img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.swiper-slide-active {
  .avatar-placeholder {
  width: 180px;
  height: 180px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 120px;
  color: #000;
  // transition: font-size width height .3s ease-in-out;
}
}
.swiper-slide-next, .swiper-slide-prev {
  .avatar-placeholder {
  width: 150px;
  height: 150px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 90px;
  color: #000;
  // transition: font-size .3s ease-in-out;
}
}
.growing-slide-avatar {
  img {
    border-radius: 50%;
  }
}
</style>
