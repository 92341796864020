<template>
  <div v-if="!isLoading">
    <b-modal id="modal-friend"   @hide="closeModal" ref="modal-friends" hide-header hide-footer class="app-modal" header-class="text-center" dialog-class="modal-dialog-centered" content-class="modal-dialog-centered" closeheader="none">
      <div>
      <div v-if="isFriend == true">
          <h2 class="text-center mb-4">Dodaj osobę do biblioteki</h2>
          <div class="info-wrapper">
            <img
            :src="userdata[0].thumb_url ? plainUrl + userdata[0].thumb_url : require('../assets/avatar-placeholder.jpg')"
            :alt="userdata.username"
            />
            <div class="mt-3">{{userdata[0].username}}</div>
          </div>
          <div>
            <button type="button" @click="addFriend"  class="btn-black-full w-100 mt-3">Dodaj</button>
            <button type="button" class="btn-gray-border w-100 mt-3 mb-2" @click="closeModal">Anuluj</button>
          </div>
        </div>
        <div v-if="isFriend == false">
          <h2 class="text-center mb-2">Ta osoba znajduje się już w Twoich znajomych.</h2>
          <h4 class="text-center mb-4">Czy na pewno chcesz ją usunąć?</h4>
          <div class="info-wrapper">
            <img :src="userdata[0].thumb_url ? plainUrl + userdata[0].thumb_url : require('../assets/avatar-placeholder.jpg')" :alt="userdata.username"/>
            <div class="mt-3">{{userdata[0].username}}</div>
          </div>
          <div>
            <button type="button" @click="removeFriend"  class="btn-black-full w-100 mt-3">Usuń</button>
            <button type="button" class="btn-gray-border w-100 mt-3 mb-2" @click="closeModal()">Anuluj</button>
          </div>
        </div>
      </div>
      <div v-if="isLoading">
        <div class="loader-wrapper">
          <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import EventBus from '../eventBus'
export default {
  name: 'ModalAddFriend',
  props: {
    userdata: Array,
    isFriend: Boolean,
    method: Function
  },
  components: {
  },
  data () {
    return {
      updateIsFriend: !this.isFriend,
      id: this.userdata[0].id,
      isLoading: false
    }
  },
  created () {
  },
  computed: {
    my_id () { return this.$store.state.my_id }
  },
  methods: {
    closeModal () {
      this.$emit('close-modal', false)
      this.$bvModal.hide('modal-friends')
      // this.$refs['modal-friends'].hide()
    },
    updateStatus () {
      EventBus.$emit('update-friend', !this.isFriend)
    },
    async addFriend () {
      this.isLoading = true
      const getData = `user=${this.userdata[0].id}`
      await this.$https('/friends', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.updateStatus()
        this.isLoading = false
      })
        .catch(error => {
          console.log('', error)
        })

      this.closeModal()
    },
    removeFriend () {
      const getData = `my_id=${this.my_id}&user=${this.userdata[0].id}`
      this.$https('/remfriends', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.updateStatus()
      })
        .catch(error => {
          console.log('', error)
        })

      this.closeModal()
    }
  },
  mounted () {
    this.$refs['modal-friends'].show()
  }
}
</script>
<style lang="scss" scoped>
  .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .info-wrapper img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
