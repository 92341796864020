<template>
  <div>
    <div class="profile-view" id="myid" :style="{backgroundImage: 'url(' + plainUrl + userInfo.img_url + ')'}">
      <section class="profile-view-content">
        <div class="container">
          <div class="profile-view-motto">{{userInfo.motto}}</div>
          <div class="profile-view-name">{{userInfo.username}}</div>
          <div class="profile-view-wrapper d-flex  justify-content-between">
            <div class="profile-view-location">{{userInfo.city}}</div>
            <div class="icon-wrapper d-flex flex-direction-row" v-if="!isBlocked">
              <router-link :to="{name: 'Messages', params: {id: userInfo.id }}">
                <div class="message-icon mr-2"><i class="fas fa-envelope"></i></div>
              </router-link>
              <div v-if="!isFriend" @click="addPerson" class="mr-2"><i class="fas fa-user-plus"></i></div>
              <div v-if="isFriend" @click="removePerson" class="mr-2"><i class="fas fa-user-minus"></i></div>
              <div @click="checkBlacklist"><i class="fas fa-user-lock"></i></div>
            </div>
          </div>
          <div class="profile-view-stats mr-0">
            <ul v-if="userInfo.show_profile == 1">
              <li @click="scrollToLibrary">{{userInfo.books_total}}<br />książek</li>
              <li class="text-center">
              <router-link class="stat-elem" :to="{name: 'ReviewOtherUser', params: {user_id: id}}"><div class="d-inline-block text-left">{{userInfo.reviews}}<br />recenzji</div></router-link>
              </li>
              <li @click="scrollToLibrary" class="text-right">
                <div class="d-inline-block text-left">{{userInfo.books_read}}<br />czyta</div>
              </li>
            </ul>
            <div v-else>
              <div class="d-inline-block text-left">Ten profil jest prywatny</div>
            </div>
          </div>
        </div>
      </section>
      <ModalAddFriend  v-if="isModalOpen && isLoaded" @close-modal="closeModal" :userdata="modalData" :isFriend="!isFriend" />
      <ModalSlot title="Blokowanie użytkownika" v-if="isPopupShown" @close-modal="close(false)">
        <div v-if="isBlocked">
          <div class="text-center mb-4 modal-main-title">Czy chcesz odblokować użytkowika?</div>
          <button class="btn-black-border w-100 mt-3 mb-1" @click="blacklist('remove')">Odblokuj</button>
        </div>
        <div v-else>
          <div class="text-center mb-4 modal-main-title">Czy chcesz zablokować użytkownika?</div>
          <button class="btn-black-border w-100 mt-3 mb-1" @click="blacklist('add')">Zablokuj</button>
        </div>
        <!-- <div class="text-center mb-4 modal-main-title">Opcje</div> -->
        <!-- <router-link :to="{name: 'EditShelf', params: {id: item.id}}"><button class="btn-black-border w-100 mt-3 mb-1" >Edytuj półkę</button></router-link>
        <button class="btn-black-border w-100 mt-3 mb-1" @click="remove(item)">Usuń półkę</button>
        <button class="btn-black-border w-100 mt-3 mb-1">Udostępni półkę</button>
        -->
        <button class="btn-black-full w-100 mt-3 mb-1" @click="close(false)">Zamknij</button>
      </ModalSlot>
      <ModalSlot title="Dodaj osobę" v-if="isAddingShown" @close-modal="closeModal(false)">
        <div class="text-center mb-4 modal-main-title">Czy chcesz dodać {{userInfo.username}} do listy znajomych?</div>
        <div class="d-flex justify-content-center align-items-center">
        <img
          :src="userInfo.thumb_url ? plainUrl + userInfo.thumb_url : require('../assets/avatar-placeholder.jpg')"
          class="people-avatar"
          :alt="userInfo.username"
        />
        </div>
        <button class="btn-black-border w-100 mt-3 mb-1" @click="addNewPerson(userInfo.id)">Dodaj</button>
        <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Anuluj</button>
    </ModalSlot>
    <ModalSlot title="Usuń osobę" v-if="isRemovingShown" @close-modal="closeModal(false)">
        <div class="text-center mb-4 modal-main-title">Czy chcesz usunąć {{userInfo.username}} do listy znajomych?</div>
        <div class="d-flex justify-content-center align-items-center">
        <img
          :src="userInfo.thumb_url ? plainUrl + userInfo.thumb_url : require('../assets/avatar-placeholder.jpg')"
          class="people-avatar"
          :alt="userInfo.username"
        />
        </div>
        <button class="btn-black-border w-100 mt-3 mb-1" @click="removeAddedPerson(userInfo.id)">Usuń</button>
        <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Anuluj</button>
      </ModalSlot>
      <ModalSlot title="Dodaj książkę" v-if="isAddedFriend" @close-modal="closeModal(false)">
        <div class="text-center mb-4 modal-main-title">Dodawanie użytkownika {{userInfo.username}}</div>
        <div class="d-flex justify-content-center align-items-center"><img src="../assets/loaders/loader_on_overlay.gif" alt="loader" /></div>
    </ModalSlot>
      <ModalSlot title="Dodaj książkę" v-if="isRemovedFriend" @close-modal="closeModal(false)">
        <div class="text-center mb-4 modal-main-title">Usuwanie użytkownika {{userInfo.username}}</div>
        <div class="d-flex justify-content-center align-items-center"><img src="../assets/loaders/loader_on_overlay.gif" alt="loader" /></div>
    </ModalSlot>
    </div>
      <div v-if="isBlocked" class="blocked-alert bg-alert pt-1 pl-3 pr-3 pb-1">
        <p>Ten użytkownik jest przez Ciebie zablokowany. Nie może z Tobą pisać, nie może komentować Twoich recenzji oraz nie pojawia się w wynikach wyszukiwania. Jeśli chcesz to zmienić <router-link to="/moje-konto/zablokowani" class="text-dark">odblokuj tego użytkownika</router-link></p>
      </div>
  </div>
</template>

<script>
import ModalAddFriend from '../components/ModalAddFriend'
import ModalSlot from '../components/ModalSlot'
export default {
  name: 'OtherUserHome',
  components: {
    ModalAddFriend,
    ModalSlot
  },
  props: {
    id: [String, Number],
    user_informations: [Array, Object]
  },
  computed: {
    userData: {
      get () {
        return this.$store.state.otherUser.userData
      }
    }
  },
  data () {
    return {
      userInfo: [],
      modalData: [],
      isModalOpen: false,
      isPopupShown: false,
      isAddingShown: false,
      isRemovingShown: false,
      isAddedFriend: false,
      isRemovedFriend: false,
      isLoaded: false,
      isBlocked: '',
      isFriend: ''
    }
  },
  created () {
    this.userInfo = this.userData.items
    this.isFriend = this.user_informations.is_my_friend
    this.isBlocked = this.user_informations.is_blocked_by_me
    this.modalData.push({
      id: this.userInfo.id,
      thumb_url: this.userInfo.thumb_url,
      username: this.userInfo.username
    })
    this.isLoaded = true
  },
  methods: {
    scrollToLibrary () {
      document.getElementById('user-library').scrollIntoView({ behavior: 'smooth' })
    },
    closeModal () {
      this.isAddingShown = false
      this.isRemovingShown = false
    },
    addPerson () {
      this.isAddingShown = true
    },
    addNewPerson (id) {
      this.isAddingShown = false
      this.isAddedFriend = true
      const getData = `user=${id}`
      this.$https('/friends', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$emit('update-friend')
        this.isAddedFriend = false
      })
        .catch(error => {
          console.log('', error)
        })
    },
    removePerson () {
      this.isRemovingShown = true
    },
    removeAddedPerson (id) {
      this.isRemovingShown = false
      this.isRemovedFriend = true
      const getData = `user=${id}`
      this.$https('/remfriends', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$emit('update-friend')
        this.isRemovedFriend = false
      })
        .catch(error => {
          console.log('', error)
        })
    },
    addFriend () {
      this.isModalOpen = true
    },
    close (value) {
      this.isPopupShown = value
    },
    checkBlacklist () {
      this.isBlocked = this.user_informations.is_blocked_by_me
      this.isPopupShown = true
    },
    blacklist (action) {
      const getData = `user=${this.id}&action=${action}`
      this.$https('/blacklist', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.isPopupShown = false
        this.isBlocked = true
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .fa-envelope {
    color: #fff;
  }
  .stat-elem {
    color: #fff;
    cursor: pointer;
  }
  .blocked-alert {
  width: 100%;
  height: 100%;
  border: 1px solid #f5c6cb;
  background-color: #f8d7da;
  color: #721c24;
  box-sizing: border-box;
  font-size: 14px;
  }
  @media screen and (max-width: 340px) {
  .profile-view .profile-view-content .profile-view-motto {
    font-size: 25px;
    line-height: 30px;
  }
  }
</style>
