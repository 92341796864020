<template>
  <div class="app-content-box bg-gray profile-status-info">
    <div class="container">
      <div class="status-icon-row-summary profile-status-summary row mt-4" v-if="isGamPublic == 1 || isGamUsed == 1">
        <div class="col-3 pr-0">
          <div class="d-inline-block text-left">
            <i class="far fa-file"></i><br />
            {{userInfo.pages_readed}} strony
          </div>
        </div>
        <div class="col-6 pl-0 pr-0 text-center">
          <div class="d-inline-block text-left">
            <i class="fas fa-book"></i><br />
            {{userInfo.books_read}} / {{userInfo.books_readed}} przeczytane
          </div>
        </div>
        <div class="col-3 pl-0 text-right">
          <div class="d-inline-block text-left">
            <i class="far fa-clock"></i><br />
           {{Math.floor(userInfo.read_time / 60) }} h {{userInfo.read_time % 60 }} m czytania
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-4" v-if="isGamPublic == 1 || isGamUsed == 1">
      <div class="top-heading-small profile-status-name text-center">
          {{userInfo.username}},
      </div>
      <div class="top-heading-medium profile-status-heading text-center">
        Poziom {{userInfo.gem_lvl}}
      </div>
    </div>
    <div v-if="isGamPublic == 1 || isGamUsed == 1">
      <div class="profile-status-avatar-spinner swiper-container">
        <div>
          <div class="profile-status-avatar-picture">
            <img :src="userInfo.thumb_url ? plainUrl + userInfo.thumb_url : require('../assets/avatar-placeholder.jpg')" alt="" />
          </div>
          <UserProfileStatusSlider :levels="levels" :gem="userGam" :gem_lvl="activeGam" />
        </div>
    </div>
    <div class="container">
      <div class="profile-status-goal" :class="{completed: (lvlData.value_1 == 1)}">
        {{lvlData.label_1}}
      </div>
      <div class="profile-status-goal" :class="{completed: (lvlData.value_2 == 1)}">
        {{lvlData.label_2}}
      </div>
      <div class="profile-status-goal" :class="{completed: (lvlData.value_3 == 1)}">
        {{lvlData.label_3}}
      </div>
      <div class="profile-status-goal" v-if="lvlData.label_4.length" :class="{completed: (lvlData.value_4 == 1)}">
        {{lvlData.label_4}}
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../eventBus'
import UserProfileStatusSlider from './UserProfileStatusSlider'
export default {
  name: 'UserProfileStatus',
  components: {
    UserProfileStatusSlider
  },
  created () {
    this.userInfo = this.userData.items
    this.isGamPublic = this.userInfo.show_gamification
    this.isGamUsed = this.userInfo.use_gamification
    this.activeGam = parseFloat(this.userInfo.gem_lvl, 10)
    EventBus.$on('gamification-levels', (payload) => {
      this.updateLvl(payload)
    })
  },
  computed: {
    userData: {
      get () {
        return this.$store.state.otherUser.userData
      }
    },
    userGam: {
      get () {
        return this.$store.state.otherUser.userGam
      }
    }
  },
  data () {
    return {
      isGamPublic: null,
      isGamUsed: null,
      activeGam: null,
      userInfo: [],
      levels: [
        'level-1', 'level-2', 'level-3', 'level-4', 'level-5',
        'level-6', 'level-7', 'level-8', 'level-9', 'level-10',
        'level-11'
      ],
      //  avatar: 'avatar-image-big.jpg'
      gem_tasks: [],
      isLoaded: false,
      lvlData: {
        label_1: '',
        label_2: '',
        label_3: '',
        label_4: '',
        value_1: '',
        value_2: '',
        value_3: '',
        value_4: ''
      }
    }
  },
  methods: {
    updateLvl (payload) {
      this.lvlData = payload
    }
  },
  beforeDestroy () {
    EventBus.$off('gamification-levels', (payload) => {
      this.updateLvl(payload)
    })
  }
}
</script>
<style lang="scss" scoped>

  .app-content-box{
    min-height: 0;
  }

  .btn-wrapper {
    padding-top: 30px;
  }

  .profile-status-avatar-picture {
    z-index: 30;
  }
</style>
