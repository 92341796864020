<template>
  <div>
      <div class="sliding-nav" v-if="isLoaded">
      <ul class="nav nav-tabs">
        <li v-for="(e, key) in library" :key="key">
          <a
            class="tab-heads"
            :class="{ active: active === e.id, 'tab-small': small}"
            @click="switchTab(e.id)"
            >
              <slot :name="tabHeadSlotName(e.id)">
                {{e.label}}
              </slot>
            </a
          >
        </li>
      </ul>
    </div>
    <div>
    <div class="tab-content">
      <div class="growing-cover-slider tab-pane-slider swiper-container">
        <div>
          <slot :name="tabPanelSlotName" v-if="isLoaded">
            <UserProfileSlider :props="books" :componentId="compId" :activeShelf="active"/>
          </slot>
          <!-- <slot :name="tabPanelSlotName" v-else>
            <EmptyShelf />
          </slot> -->
        </div>
        <div class="container" v-if="this.library.length">
    <router-link :to="{name: 'Authors', params: {id: bookData.autor_id}}" class="text-dark"><div class="book-heading-small mt-2 text-center">{{bookData.autor}}</div></router-link>
    <router-link :to="{name: 'BooksDetails', params: {id: bookData.id}}" class="text-dark"><div class="book-heading-big mt-2 text-center">{{bookData.tytul}}</div></router-link>
        <div class="stars-wrapper mt-1" v-if="bookData.rating > 0">
          <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
          inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
          :border-width='borderWidth' :read-only="true" :show-rating="false"
          :rounded-corners='rounded' :rating="bookData.rating" >
          </star-rating>
        </div>
          <div class="rating-size text-center mt-2"  v-if="bookData.rating > 0">Ocena: {{bookData.rating}}</div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../eventBus'
import UserProfileSlider from './UserProfileSlider'
import StarRating from 'vue-star-rating'
// import EmptyShelf from './EmptyShelf'
export default {
  name: 'OtherUserTabs',
  components: {
    UserProfileSlider,
    StarRating
    // EmptyShelf
  },
  props: {
    tabs: Array,
    activeTab: [String, Number],
    small: Boolean,
    library: Array,
    compId: [String]
  },
  data () {
    return {
      active: this.activeTab,
      isLoaded: false,
      selectedBooks: this.selectedSlides,
      booksIndex: '',
      books: [],
      bookData: {
        id: 0,
        image: null,
        autor: ' ',
        tytul: ' ',
        rating: -1,
        autor_id: ' '
      },
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18
    }
  },
  created () {
    this.getBooks(this.active)
    EventBus.$on('books-in-' + this.compId, (payload) => {
      this.updateData(payload)
    })
  },
  computed: {
    tabPanelSlotName () {
      return 'tab-panel-' + this.active
    }
  },
  methods: {
    tabHeadSlotName (tabName) {
      return 'tab-head-' + tabName
    },
    switchTab (tabName) {
      this.active = tabName
      this.getBooks(this.active)
    },
    updateData (payload) {
      this.bookData = payload
    },
    getBooks (id) {
      this.booksIndex = this.library.map(function (e) {
        return e.id
      })
        .indexOf(id)
      this.books = this.library[this.booksIndex].items
      this.isLoaded = true
    }
  },
  beforeDestroy () {
    EventBus.$off('books-in-otherUserTab', (payload) => {
      this.updateData(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .tab-small {
    font-size: 16px;
  }
  .tab-content {
    min-height: 100px;
  }
  .stars-wrapper {
    display: flex;
    justify-content: center;
  }
  .rating-size {
    font-size: 14px;
  }
</style>
