<template>
  <div class="bg-gray mb-5" id="user-library">
    <div class="container wrapper-other-library">
      <div class="top-heading-small text-center">Top półki</div>
    </div>
    <OtherUserTabs :activeTab="activeTab" :tabs="tabs" compId="otherUserTop" :library="library">
      <div v-for="(e, key) in library" :key="key">
      <template :slot="'tab-head-' + e.id" >
      </template>
      <template :slot="'tab-panel-' + e.id">
      </template>
    </div>
    </OtherUserTabs>
  </div>
</template>
<script>
import OtherUserTabs from './OtherUserTabs'
export default {
  name: 'OtherUserLibrary',
  props: {
    library: Array
  },
  components: {
    OtherUserTabs
  },
  created () {
    // this.library.forEach(e => {
    //   if (e.type === 'top') {
    //     this.userShelves.push(e)
    //   }
    // })
    // this.library.forEach(e => {
    //   if (e.type === 'top') {
    //     this.top.push({
    //       id: e.id,
    //       name: e.label
    //     })
    //   }
    // })
    // this.isLoaded = true
    this.activeTab = this.library[0].id
  },
  methods: {
  },
  data () {
    return {
      activeTab: 0,
      shelves: [],
      tabs: [],
      userShelves: [],
      isLoaded: false
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper-other-library {
    // margin-bottom: 60px;
    // padding-bottom: 30px;
  }
  #user-library {
    // padding-bottom: 100px;
  }
</style>
