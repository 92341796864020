<template>
  <div class="app-content-box bg-gray">
    <div class="container">
      <div class="top-heading-small text-center" v-if="proposed.length">Łączą was</div>
    </div>
    <TabsProposed :activeTab="activeTab"
      :tabs="proposed" v-if="isLoaded && proposed.length"
      @update="updateFriends"
      :idName="idName"
    >
      <div v-for="(el, key) in proposed" :key="key">
        <template :slot="'tab-head-' + el.label" >
        </template>
        <template :slot="'tab-panel-' + el.label">
        </template>
      </div>
    </TabsProposed>
  </div>
</template>
<script>
import TabsProposed from './TabsProposed'
export default {
  name: 'OtherUserCommons',
  props: {
    commons: Object
  },
  components: {
    TabsProposed
  },
  data () {
    return {
      activeTab: '',
      tabs: [],
      proposed: [],
      isLoaded: false,
      idName: 'otherUserCommons'
    }
  },
  methods: {
    updateFriends () {
      this.$emit('update')
    }
  },
  created () {
    this.proposed = []
    if (this.commons.books.length) {
      this.proposed.push(this.commons.books.length ? { id: 0, label: 'Książki', items: this.commons.books, type: 'books' } : '')
      this.tabs.push('Książki')
    }
    if (this.commons.users.length) {
      this.proposed.push(this.commons.users.length ? { id: 1, label: 'Osoby', items: this.commons.users, type: 'person' } : '')
      this.tabs.push('Osoby')
    }
    this.activeTab = this.tabs[0]
    this.isLoaded = true
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box{
    min-height: 0;
  }
</style>
